<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/article/list')" class="el-icon-close"></i>
      </div>
      <div class="marigtn fle" style="    padding-top: 20px;">
        <div style="display: flex;padding: 0;">
          <div>内容编号：{{form.id}}</div>
          <div>发布时间：{{form.edit_at}}</div>
        </div>
        <div style='display:flex;'>
          <div>审核状态：{{form.active_status==0?'未审核':''}}{{form.active_status==1?'通过':''}}{{form.active_status==2?'未通过':''}}</div>
          <div>审核编号：{{form.examine?form.examine.no:''}}</div>
        </div> 

      </div>
      <div class="pt20"></div>
      <el-descriptions title="基本信息" direction="vertical" :column="3" border>
        <el-descriptions-item labelStyle='color:#606266;font-weight: bold;' label="发布账号">{{form.user.phone}}</el-descriptions-item>
        <el-descriptions-item labelStyle='color:#606266;font-weight: bold;' label="用户编号">{{form.user.uid}}</el-descriptions-item>
        <el-descriptions-item labelStyle='color:#606266;font-weight: bold;' label="会员等级">{{form.user.level}}</el-descriptions-item>
        <el-descriptions-item labelStyle='color:#606266;font-weight: bold;' label="认证用户">
          <el-tag size="small">{{form.user.is_authentication?'是':'否'}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item labelStyle='color:#606266;font-weight: bold;' label="是否与投诉订单联动"><el-tag size="small">{{form.order_no?'是':'否'}}</el-tag></el-descriptions-item>
        <el-descriptions-item labelStyle='color:#606266;font-weight: bold;' label="达人值">{{form.user.talent_integral}}</el-descriptions-item>
      </el-descriptions>
    <div class="form">
        <div class="fle">
            <!-- <h3 class="bgl" style="    margin-top: 0;">基本信息</h3>
            <div style="display: flex;">
                <div>发布账号：<input type="text" disabled v-model="form.user.phone"></div>
                <div>用户编号：<input type="text" disabled v-model="form.user.uid"></div>
                <div>会员等级：<input type="text" disabled v-model="form.user.level"></div>
            </div>
            <div style="display: flex;">
                <div>认证用户：<input v-if="form.user.is_authentication" type="text" disabled value="是"><input v-if="!form.user.is_authentication" type="text" disabled value="否"></div>
                <div>是否与投诉订单联动：<input type="text" v-if="form.order_no" disabled value="是"><input type="text" v-if="!form.order_no" disabled value="否"></div>
                
                <div>达人值：  <input type="text" disabled v-model="form.user.talent_integral"></div>
            </div>
             -->
            <h3 style="margin-top: 10px;" class="bgl">内容信息</h3>
            <div>
                <div>分类：<input style="width:470px;" type="text" v-if="form.category" disabled v-model="form.category.name"></div>
                <div>标题：<input style="width:470px;" type="text" disabled v-model="form.title"></div>
                <div>话题：<input style="width:470px;" v-if="form.topic" type="text" disabled v-model="form.topic.name">{{form.topic.belong==2?'(自定义)':''}}</div>
                <div>航司：<input style="width:470px;" v-if="form.aircompany" type="text" disabled v-model="form.aircompany.name"><input style="width:470px;" v-if="!form.aircompany" type="text" disabled ></div>
                <div>定位：<input style="width:470px;" type="text" disabled v-model="form.location">{{form.lat || !form.location?'':'(自定义)'}}</div>
            </div>
            <div>
                图片：
            </div>
            <div class="flex">
                <div class="block" v-for="fit in form.images" :key="fit.id">
                    <el-image
                    style="width: 100px; height: 100px;" class="im"
                    :src="fit.images_url"
                    :preview-src-list="imgsrcall"
                    fit="cover"></el-image>
                </div>
            </div>
            <div>
                正文：<br>
                <textarea type="text" style="width:500px;min-height:100px;" disabled v-model="form.content"></textarea>
            </div>
             <h3 class="bgl">内容数据</h3>
             <div style="display:flex;">
                <div>
                    <div>获赞数：<input type="text" disabled v-model="form.liked"></div>
                    <div>被收藏数：<input type="text" disabled v-model="form.collection_num"></div>
                </div>
                <div>
                    <div>被屏蔽数：<input type="text" disabled v-model="form.shield_count"></div>  
                    <div>评论数：<input type="text" disabled v-model="form.comment_num"></div> 
                </div>
                <div>
                    <div>被举报数：<input type="text" disabled v-model="form.report_count"></div> 
                </div>
            </div>
        </div>
            <h3 class="bgl">运营操作信息</h3>
            <div style='display: flex;'>
               <el-checkbox v-model="form.is_recommend">评为精选内容</el-checkbox>
                <div style='margin-left: 10px;' class="flex">内容标签：
                   <div class="pw" v-for="label in form.label" :key="label.label_id">{{label.label?label.label.name:''}}</div>
                   <el-button type="primary" @click="goedit(form.label)" size="mini" icon="el-icon-edit"></el-button>
                </div>
            </div>
      <el-form ref="form" :model="form" label-width="180px">
      <el-form-item>
        <el-button v-if="!look" type="primary" style="margin-left: 45%;" @click="onSubmit">确认</el-button>
      </el-form-item>
    </el-form>
</div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      look:false,
      imgsrcall:[],
      form: {
        id:0,
        created_at:'',
        user:{
            id:0,
        },
        category:{
            name:'',
        }, 
        topic:{
            name:'',
            belong:2,
        },  
        aircompany:{
            name:'',
        }, 
        },
        options:[],
        options2:[],
        islist:0,
    }
  },
  methods: {
    handleSelect(url){
      if(this.islist){
        this.$router.push(url)
        }else{
        this.$router.push('/article/list2');
        }
    },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      postdata.c_name = postdata.second;
      let postdataid = []
      for(let i in postdata.label){
          postdataid.push(postdata.label[i].label_id);
      }
      let obj = {
          id:postdata.id,
          updated_at:postdata.updated_at,
          is_recommend:postdata.is_recommend?1:0,
          label:postdataid,
      }
      axios.post(config.article,obj)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav('/article/list');
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        if(this.islist == 1){
          this.$router.push(url)
        }else{
          this.$router.push('/article/list2');
        }
      },
      goedit(obj){
        let arr = [];
        console.log(obj)
        for(let i in obj){
            arr.push({
                id:obj[i].label.id,
                name:obj[i].label.name,
            })
        }
        sessionStorage.setItem("artedit", JSON.stringify(arr));
        sessionStorage.setItem("gpc", 1);
        sessionStorage.setItem("art", 0);
        this.$router.push('/article/editarticlelabel');
      },
    init(a,b){
        let _this = this;
        let obj = JSON.parse(a);
        _this.look = obj.look;
        let objb = b==''?'':JSON.parse(b);
        axios.get(config.articleInfo,{params:{id:obj.id}})
        .then(function (response) {
            if(response.data.code == 200){
                _this.form = response.data.data;
                _this.form.is_recommend = _this.form.is_recommend==1?true:false;
                if(objb.length > 0){
                    _this.form.label = [];
                    for(let i in objb){
                    _this.form.label.push({
                            label_id:objb[i].id,
                            label:{id:objb[i].id,name:objb[i].name}
                        })
                    }
                }
                if(_this.form.images){//图片预览 格式过滤
                  for(let i in _this.form.images){
                    _this.imgsrcall.push(_this.form.images[i].images_url);
                  }
                }
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created(){
     let a = sessionStorage.getItem('edit');
     let b = sessionStorage.getItem('artedit');
     if(sessionStorage.getItem('islist') == 1){
       this.islist = 1;
     }
      this.init(a,b);
  }
}
</script>
<style scoped>
.form{
    margin-right: auto;
    text-align: left;
}
.flex{
    display: flex;
}
.left{
  margin-left: 30px;
}
.marigtn{
    margin-left: auto;
    width: 450px;
    text-align: left;
}
.im{
   
}
.pw{
    margin-right: 7px;
}
.el-icon-close:before{
  font-size: 24px;
}
 div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}
.fle div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 0 12px 10px 0;
    box-sizing: border-box;
    font-weight: bold;
}
.fle div input{
  height: 20px;
}
.bgl{
    background-color: aliceblue;
    padding: 10px;
}
</style>
